/**
 * The root of the react application. This is where it all comes together -
 * follow the imports of this file to find everything that is included in the
 * application.
 */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import './styles/index.css'



ReactDOM.render(<App />, document.getElementById("root"))
