import React from "react";
import {
    withRouter,
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Dashboard from './module/logindashboard'
import { useHistory } from "react-router-dom";
import Home from './module/home'


const App = () => {


    return (
        <div>
            <Router><Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/loginsuccess">
                    <Dashboard />
                </Route>
            </Switch>
            </Router>
        </div>
    )





};

export default App;