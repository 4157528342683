
import { getEnvironment } from "./environment";

const getConfig = stage => {
    if (stage === "prod-pdx") {
        return {
            logout_url: "https://portal.us-west-2.prod.passport.legal.amazon.com/logout",
            authority: 'https://idp.federate.amazon.com/',
            clientId: 'LegalPassport-TestEndService1-oidc-implicit-us-west-2-prod',
            responseType: 'id_token',
            redirectUri: 'https://test-client.us-west-2.prod.passport.legal.amazon.dev'
        }
    }
    else if (stage === "gamma-pdx") {
        return {
            logout_url: "https://portal.us-west-2.gamma.passport.legal.amazon.com/logout",
            authority: 'https://idp-integ.federate.amazon.com/',
            clientId: 'LegalPassport-TestEndService1-oidc-implicit-us-west-2-gamma',
            responseType: 'id_token',
            redirectUri: 'https://test-client.us-west-2.gamma.passport.legal.amazon.dev'
        }
    }
    else if (stage === "beta-pdx") {
        return {
            logout_url: "https://portal.us-west-2.beta.passport.legal.amazon.com/logout",
            authority: 'https://idp-integ.federate.amazon.com/',
            clientId: 'LegalPassport-TestEndService1-oidc-implicit-us-west-2-beta',
            responseType: 'id_token',
            redirectUri: 'https://test-client.us-west-2.beta.passport.legal.amazon.dev'
        }

    }
    else if (stage === "alpha-pdx") {
        return {
            logout_url: "https://portal.us-west-2.alpha.passport.legal.amazon.com/logout",
            authority: 'https://idp-integ.federate.amazon.com/',
            clientId: 'LegalPassport-TestEndService1-oidc-implicit-us-west-2-alpha',
            responseType: 'id_token',
            redirectUri: 'https://test-client.us-west-2.alpha.passport.legal.amazon.dev'
        }

    }
    else {
        return {
            logout_url: "https://portal.us-west-2.alpha.passport.legal.amazon.com/logout",
            authority: 'https://idp-integ.federate.amazon.com/',
            clientId: 'LegalPassport-TestEndService1-oidc-implicit-us-west-2-alpha',
            responseType: 'id_token',
            redirectUri: 'https://test-client.us-west-2.alpha.passport.legal.amazon.dev'
        }

    }
}
const appconfig = getConfig(getEnvironment());

export default appconfig;
