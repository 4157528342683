import React, { Component, useState, useEffect } from "react";

import { withRouter, useHistory } from "react-router-dom";
import AppConfig from "../config/app-config";
import Header from "../component/header"
import Loading from "../component/loading"
import { Alert, Card, Container, Form, Col, Row, Button } from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { AuthProvider } from 'oidc-react';
import { render } from "react-dom";

const Home = () => {

    let history = useHistory();
    const oidcConfig = {

        authority: AppConfig.authority,
        clientId: AppConfig.clientId,
        responseType: AppConfig.responseType,
        redirectUri: AppConfig.redirectUri,
        loadUserInfo: true,
        onSignIn: async (user) => {
            const userData = await user;
            console.log(userData)
            history.push('/loginsuccess', {
                user: userData
            });
        }
    }

    const [logindelay, setlogindelay] = useState();

    useEffect(() => {

        const timer = setTimeout(() => {
            console.log("Login Delay")
            setlogindelay(<p className="ml-5 mt-2 mr-2 alert alert-danger " > Please close the browser / browser tab after logout and try accessing the site from new tab / browser ...</p>)

        }, 5000);

    }, []);





    return (<AuthProvider {...oidcConfig}>
        <div>
            <Header />
            {logindelay}
            <Loading />

        </div>
    </AuthProvider>
    );


}

export default Home;