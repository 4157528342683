import React, { Component } from "react";
import { withRouter, useHistory } from "react-router-dom";
import AppConfig from "../config/app-config";
import Header from "../component/header"
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { Button } from 'react-bootstrap';

let loginDashboard;

const Dashboard = () => {

    let history = useHistory();
    const userData = JSON.stringify(history.location.state);
    var JSONPrettyMon = require('react-json-pretty/dist/monikai');

    console.log(userData)
    function handleClick(e) {
        e.preventDefault();
        console.log('The link was clicked.');
        window.location.href = AppConfig.logout_url
    }

    function Home() {
        window.location.href = "/"
    }

    return loginDashboard = userData ?
        (
            <div>
                <Header />
                <h1 className="ml-5">LPS Login Successful</h1>
                <Button className="amzn-btn mb-2 ml-5" onClick={handleClick} >Logout</Button>
                <br />
                <JSONPretty className="mb-2 ml-5" id="json-pretty" data={userData} theme={JSONPrettyMon} ></JSONPretty>
            </div>) : (<Home />)




}

export default Dashboard;