import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


const header = () => {
    return (<header className="header">
        <div className="row justify-content-between align-items-center" style={{ width: "100 %" }}>
            <div className="col">
                <img className="logo-img" src="/images/Logo.png" alt="Amazon Legal Logo" />
            </div>
            <div className="col col-auto navigation-container">
                <div className="hamburger" data-toggle="main-nav">
                    <div className="hamburger-inner"></div>
                </div>
                <nav className="navigation hide-menu-mobile" id="main-nav">
                    <ul className="navigation-list"></ul>
                </nav>
            </div>
        </div>
    </header>);
}
export default header;
